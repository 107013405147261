<template>
  <div class="container h-100">
    <div class="row justify-content-center h-100 align-items-center">
      <div class="col-6">
        <div class="thank-you-content d-flex flex-column align-items-center">
          <a href="/app/dashboard" class="d-inline-flex justify-content-center"
            ><img src="/img/logo.svg" alt="" class="logo my-5"
          /></a>
          <h3 class="text-center mb-2 pb-2">{{ $t("thankyou.thankyou") }}</h3>
          <p class="text-center text-secondary">
            {{ $t("thankyou.thankyou-p") }}
          </p>

          <a class="btn btn-primary btn-sm go-back-btn" href="/landing">{{
            $t("header.home")
          }}</a>

          <div class="thank-you-img mt-5">
            <img src="/img/thank-you.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CREATE_CONVERSION from '@/graphql/me/createConversion.graphql';
import _ from 'lodash';
import cookies from 'js-cookie';
export default {
  name: 'thankYou',
  methods: {
      async check() {
          try {
              if (_.isNil(this.$route.query.hash) === false) {
                await this.$apollo.mutate(
                    CREATE_CONVERSION,
                    {
                        tracking_id: cookies.get('tap_vid'),
                        checkout_id: this.$route.query.hash
                    }
                );
              }
          } catch (error) {
              console.log(error);   
          }
      }
  },
  mounted() {
      this.check();
  },
};
</script>

<style lang="scss">
.go-back-btn {
  display: block;
  width: 30%;
  margin-top: 40px;
}
.thank-you-content {
  .thank-you-img {
    display: flex;
    justify-content: center;
    img {
      width: 50%;
    }
  }
}
</style>